.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-menu {
  background-color: whitesmoke;
  height: 20px;
  font-weight: bold;
  margin: 0;
  border-top: 8px #ff1aba solid ;
  border-bottom: 1px #C0C0C0 solid ;
  padding: 8px;
}

.App-menu > li {
  display: inline-block;
  padding: 0px 8px;
}

.App-menu > li > a, .App-menu > li > a {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.App > section {
  max-width: 800px;
  margin: auto;
  text-align: left;
  padding: 0 20px 0 20px;
}

a, a:visited {
  color: orangered;
  cursor: pointer;
}

button {
  border: 0;
  background-color: #50cc00;;
  color: white;
  float: right;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
  font-weight: bold;
  padding: 8px 8px;
}

button:disabled {
  background-color: lightgrey;
  color: darkgrey;
  border: none;
  cursor: default;
}

button:hover {
  border-width: 2px;
  position: relative;
  top: -1px;
  right: -1px;
}

      .pagelocalreps path {
        pointer-events: all;
      }
      .pagelocalreps path:hover {
        opacity: 0.50;
        cursor: pointer;
      }

      .pagelocalreps .mapsegment {
        margin-bottom: 25px;
      }
/************************************************/

.ui.vertical.segment.footer {
  margin-top: 4em;
}


.desktopChildren .video-container {
  width:80%;
  margin-left: auto;
  margin-right: auto;
}
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 15px;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.quantityInput input[type="text"] {
  width: 6em;
}

.pagepartgroup .inventory {
  margin-top: 40px;
}

.qtyLocation {
  font-weight: bold;
}