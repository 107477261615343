.pagehome .ui.inverted.segment.DesktopSegment {
    min-height: 500px;
    padding: 1em 0em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.DesktopContainer .mainLogo {
    margin-top: 4em;
}

.DesktopContainer .item.partSearch {
    padding: 0 0 0 10px !important;
}

.DesktopContainer .menu .partSearch input[type=search] {
    background-color: rgba(0,0,0, 0.25);
    color: #fff;
}
.DesktopContainer .menu .partSearch input[type=text] {
    background-color: rgba(0,0,0, 0.25);
    color: #fff;
}

.DesktopContainer .menu .partSearch input[type=text] i {
    color: #fff;
}

.DesktopContainer .ui.menu.fixed .item.partSearch:before {
    width: 0;
}

.DesktopContainer .menu.fixed .partSearch input[type=search] {
    background-color: rgba(255,255,255, 1);
    color: #000;
}
.DesktopContainer .menu.fixed .partSearch input[type=text] {
    background-color: rgba(255,255,255, 1);
    color: #000;
}
.ui.secondary.inverted.menu.DesktopMenu {
  background-color: rgba(0, 0, 0, 0.25) !important
}

.DesktopContainer .desktopChildren {
    padding-top: 4em;
}

.pagehome .DesktopContainer .desktopChildren {
    padding-top: 0;
}

.DesktopContainer .ui.search .ui.icon i {
    vertical-align: middle;
    font-size: 1.5em;
}

.DesktopContainer .menu.fixed .ui.search .ui.icon i {
    color: #000;
}

.DesktopContainer .menu .ui.search .ui.icon i {
    color: #fff;
}

_:-ms-fullscreen, :root div.mapsegment > div { height: 593px; }
_:-ms-fullscreen, :root div.mapsegment { height: 650px; }